<template>
  <dash-page-new
      :title="$t('PlansAndPayments')"
      :root="$t('Dashboard')"
      :subtitle="$t('Overview')"
      :root-location="businessDashLink('')"
      icon="mdi-credit-card-marker"
      no-side
  >


    <template #header_action>
      <v-sheet width="100%" color="transparent" :class="SM ? 'd-flex justify-center' : null">
        <ws-toggle
            v-model="navigation"
            @input="navigationAction"
            :text-true="$t('Overview')"
            :text-false="$t('Orders')"
        ></ws-toggle>
      </v-sheet>

    </template>

  <router-view></router-view>

  </dash-page-new>
</template>

<script>

export default {
  name: "PlansAndPayments",
  data() {
    return {
      navigation : true
    }
  },
  computed : {
    navigationItems() {
      return [
        {text: 'Огляд', icon: 'mdi-cog', value: ''},
        {text: 'Активні Рахунки', icon: 'mdi-text-box-check-outline' , value: 'orders'},
        {text: 'Архів оплат', icon: 'mdi-text-box-check-outline'     , value: 'archive'}
      ]
    },
  },
  methods : {
    navigationAction(value) {
      let route = 'plans_payments' + (!value ? '/orders' : '')
     this.$router.push( this.businessDashLink(route) )
    }
  }
}
</script>

<style scoped>

</style>